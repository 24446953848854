<template>
  <div
    class="asset-display-root at-media-item"
    :class="{selected: selected}"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div
      v-if="selected"
      class="asset-selected"
    >
      <GjIcon
        class="selected-icon"
        name="Check_fill"
        size="25"
      />
    </div>

    <b-aspect
      ratio="1:1"
      class="asset-display"
      :class="{'asset_expired-date': isAfter}"
    >
      <div
        v-if="isDeleted"
        class="deleted-media"
      >
        <span class="deleted-asset">
          <GjIcon
            name="HideAlt"
            size="28"
            style="fill: #9595A0;"
          />
        </span>
      </div>
      <div
        v-else
        class="asset-overlay"
      >
        <div class="asset-overlay__left">
          <div class="asset-overlay__left-top" />
          <div class="asset-overlay__left-bottom">
            <div
              v-if="asset.type === ASSET_TYPES.IMAGE"
              class="asset-resolution"
            >
              {{ asset.width }} x {{ asset.height }}
            </div>
          </div>
        </div>
        <div class="asset-overlay__right">
          <div class="asset-overlay__right-top">
            <div
              class="dropdown-holder"
              @click.self="handleClick"
            >
              <div
                v-if="!disable && asset.uploadStatus !== 'uploading'"
                right
                variant="flat-primary"
                class="dropdown-icon-wrapper asset-context-menu at-gallery-lists-create-more-info-asset-dropdown"
                size="sm"
              >
                <span
                  v-if="!isDeleted"
                  class="asset-context-menu-element"
                >
                  <span
                    id="at-asset-hover-edit-asset-button"
                    class="at-gallery-lists-create-asset-edit-button"
                    @click="openAsset"
                  >
                    <GjIcon
                      name="Edit"
                      size="20"
                    />
                  </span>
                </span>
                <span
                  v-if="asset.type === ASSET_TYPES.IMAGE"
                  id="at-asset-hover-image-editor-button"
                  v-permission="['canUploadAsset', { folderId: asset.folderId }]"
                  class="asset-context-menu-element"
                  @click="openImageEditor"
                >
                  <GjIcon
                    name="Crop"
                    size="20"
                  />
                </span>
                <span
                  v-if="asset.type === ASSET_TYPES.IMAGE"
                  v-permission="['canEditAsset', { id: asset.id }]"
                  class="asset-context-menu-element"
                  @click="focalPoint(asset)"
                >
                  <GjIcon
                    name="IconparkFocus"
                    size="20px"
                  />
                </span>
                <span
                  v-if="asset.type === ASSET_TYPES.IMAGE || asset.type === ASSET_TYPES.FILE"
                  id="at-asset-hover-download-button"
                  class="asset-context-menu-element"
                  @click="downloadAsset(asset)"
                >
                  <GjIcon
                    name="Download"
                    size="20"
                  />
                </span>
                <span
                  id="at-asset-hover-delete-asset-button"
                  v-permission="mouseOver ? ['canDeleteAsset', { id: asset.id }] : ['canDeleteAsset', { folderId: asset.folderId }]"
                  class="asset-context-menu-element"
                  @click="$emit('deleteAsset', asset)"
                >
                  <GjIcon
                    name="DeleteTrash"
                    size="20"
                  />
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="asset.expiresAt"
            class="asset-overlay__right-bottom"
          >
            <div class="asset-overlay__expire">
              <GjIcon
                v-if="isAfter"
                v-b-tooltip
                :title="$t('assets.expired-date', { date: formatDateTime(asset.expiresAt) })"
                class="asset-overlay__expire-expired"
                name="Denied"
                size="16"
              />
              <span
                v-else
                class="asset-overlay__expire-info"
              >
                <GjIcon
                  v-b-tooltip
                  name="Info_fill"
                  size="16"
                  :title="$t('assets.expires-date', { date: formatDateTime(asset.expiresAt) })"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <template v-if="asset.type">
        <template v-if="asset.type === ASSET_TYPES.VIDEO || asset.type === ASSET_TYPES.AUDIO || asset.type === ASSET_TYPES.PODCAST">
          <div class="video__play-icon">
            <GjIcon
              :name="typeIcon"
              size="24"
              @click.native="handleClick"
              @dblclick.native="maybeOpenAsset"
            />
          </div>
          <div
            v-if="attrs.isLiveStream === 'true' || attrs.isLiveStream === true"
            class="video__live-stream-icon"
          >
            <div class="video__live-stream-icon__dot" />
            <span class="video__live-stream-icon__text">
              {{ $t('assets.videos.live') }}
            </span>
          </div>
          <div
            v-if="asset.status === ASSET_STATUSES.FAILED"
            class="video__failed-icon"
          >
            <GjIcon
              name="CloseCancelCircleFilled"
              size="16"
              class="text-danger"
            />
            <span class="video__failed__text text-danger">
              {{ $t('assets.upload.failTitle') }}
            </span>
          </div>
          <div
            v-if="asset.status === ASSET_STATUSES.PROCESSING"
            class="video__processing-icon"
          >
            <GjIcon
              name="IconparkLoadingOne"
              size="16"
              class="text-primary"
            />
            <span class="video__processing__text">
              {{ $t('assets.upload-progress.processing') }}
            </span>
          </div>
          <div class="video__icons">
            <div class="video__icon">
              <img
                src="@/assets/icons/vp-icon.svg"
                class="video__vp-icon"
                alt=""
              >
            </div>
            <div
              v-if="attrs.isLive === 'true' || attrs.isLive === true"
              class="video__icon"
            >
              <GjIcon
                name="Livestream - main"
                size="16"
              />
            </div>
          </div>
        </template>
        <template v-if="!errorLoading && displayType !== ASSET_TYPES.FILE && displayURL">
          <img
            :key="displayURL"
            :src="displayURL"
            alt=""
            @click="handleClick"
            @dblclick="maybeOpenAsset"
            @error="() => errorLoading = true"
          >
        </template>

        <template v-else>
          <b-card
            draggable
            class="file__card at-media-item-image-card-button"
            body-class="d-flex flex-column"
            @click="handleClick"
            @dblclick="maybeOpenAsset"
          >
            <div class="file__card-body">
              <div class="file__card-image-container">
                <img
                  v-if="asset.type === ASSET_TYPES.FILE"
                  :src="require('@/assets/images/icons/file.svg')"
                >
                <img
                  v-else-if="asset.type === ASSET_TYPES.VIDEO_PLAYLIST || asset.type === ASSET_TYPES.VIDEO"
                  :src="require('@/assets/images/icons/video-playlist.svg')"
                >
                <img
                  v-else-if="asset.type === ASSET_TYPES.AUDIO"
                  :src="require('@/assets/images/icons/audio.svg')"
                >
                <img
                  v-else-if="asset.type === ASSET_TYPES.PODCAST"
                  :src="require('@/assets/images/icons/podcast.svg')"
                >
              </div>
            </div>
            <b-card-footer footer-class="file__card-footer">
              <p class="file__card-footer-filename">
                {{ asset.type !== ASSET_TYPES.FILE ? asset.title : asset.fileName }}
              </p>
            </b-card-footer>
          </b-card>
        </template>
      </template>
    </b-aspect>
    <div
      v-if="asset && showDetails"
      class="asset-details"
    >
      <span
        v-if="asset.title"
        class="asset-details__title"
      >
        {{ asset.title }}
      </span>
      <p class="asset-details__date">
        {{ asset.createdAt | formatDate(true) }}
      </p>
      <p
        v-if="asset.source || asset.author"
        class="asset-details__source-author"
        :title="asset.source"
      >
        {{ asset.source }}
        <span
          v-if="asset.source && asset.author"
          class="asset-details__circle"
        />
        {{ asset.author }}
      </p>
      <div
        v-if="asset.labels && asset.labels.length > 0"
        class="media-image__labels"
      >
        <div
          v-for="label in asset.labels.slice(0, 2)"
          :key="label.id"
          class="media-image__label"
        >
          <GjIcon
            :key="label.id"
            name="IconparkDot"
            :color="label.color"
            size="10"
          />
          {{ label.name }}
        </div>
        <b-dropdown
          v-if="asset.labels.length > 2"
          size="sm"
          variant="outline"
          class="media-image__labels-dropdown"
          toggle-class="btn-gradient-no-gradient"
          no-caret
        >
          <template #button-content>
            <GjIcon
              name="Moreoptions"
              size="10px"
            />
          </template>
          <b-dropdown-item
            v-for="label in asset.labels.slice(2)"
            :key="label.id"
            @click.native.capture.stop.prevent
          >
            <GjIcon
              :key="label.id"
              name="IconparkDot"
              :color="label.color"
              size="10"
            />
            {{ label.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div
        v-if="asset.tags && asset.tags.length>0"
        class="asset-details__tags"
      >
        <div
          v-for="tag in asset.tags.slice(0, 2)"
          :key="tag"
          class="asset-details__tag"
          :title="tag"
        >
          {{ tag }}
        </div>
        <b-dropdown
          v-if="asset.tags.length > 2"
          variant="none"
          no-caret
          class="asset-details__tags"
        >
          <template #button-content>
            <GjIcon
              name="Moreoptions"
              size="16"
            />
          </template>
          <b-dropdown-item
            v-for="tag in asset.tags.slice(2)"
            :key="tag"
            class="asset-details__tag"
          >
            {{ tag }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ASSET_STATUSES, ASSET_TYPES, readAsset, updateAsset,
} from '@/codex-sdk/assets'
import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
import gql from 'graphql-tag'
import { downloadAsset } from '@/utils/helpers'

export default {
  props: ['asset', 'disable', 'isPopup', 'showDetails', 'assets', 'folderId'],
  inject: ['showImageEditorPopup', 'showFocalPointPopup'],
  apollo: {
    currentAsset: {
      query: gql`
        query ($id: String!) {
         currentAsset: asset (id: $id){
            id
            folderId
            type
            title
            contentType
            createdAt
            expiresAt
            size
            url(transformation: {width: 200,height: 200,quality: 60, resize: FILL})
            fileName
            caption
            alt
            source
            author
            copyright
            tags
            attrs
            focalPoint {
              x
              y
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          id: this?.id,
        }
      },
      result(result) {
        if (result?.data?.currentAsset) {
          this.UPDATE_SELECTED_ASSET(result.data.currentAsset)
          Object.assign(this.asset, result.data.currentAsset)
        }
        return result
      },
      skip() {
        return !this?.id
      },
    },
  },
  data() {
    return {
      downloadAsset,
      ASSET_TYPES,
      ASSET_STATUSES,
      id: null,
      mouseOver: false,
      errorLoading: false,
    }
  },
  computed: {
    ...mapState('assets', [
      'assetId',
      'selectedData',
    ]),
    isDeleted() {
      return this.asset.status === ASSET_STATUSES.DELETED
    },
    selectedAssets: {
      get() {
        return this.selectedData.items
      },
      set(v) {
        if (v.length <= this.selectedData.limit) {
          let totalSize = 0
          v.forEach(a => { totalSize += a.size })
          const addedItem = v.length > this.selectedData.items.length

          this.SET_SELECTED_DATA({
            ...this.selectedData,
            totalSize: totalSize || 0,
            items: v,
            total: v.length,
            lastSelection: addedItem ? v[v.length - 1] : this.asset,
          })
        } else if (this.selectedData.limit == 1 && v.length == 2) {
          const array = [v[1]]
          let totalSize = 0
          array.forEach(a => { totalSize += a.size })
          this.SET_SELECTED_DATA({
            ...this.selectedData,
            totalSize: totalSize || 0,
            items: array,
            total: array.length,
            lastSelection: v[1],
          })
        }
      },
    },
    selected() {
      return this.selectedAssets.find(x => x?.id === this.asset?.id)
    },
    isAfter() {
      return moment().isAfter(this.asset.expiresAt)
    },
    displayURL() {
      return this.asset?.url || this.currentAsset?.url
    },
    displayType() {
      if (this.asset?.url) return this.asset?.type
      if (this.currentAsset?.url) return this.currentAsset?.type
      return ''
    },
    attrs() {
      return this.asset?.attrs || {}
    },
    typeIcon() {
      if (this.asset.type === ASSET_TYPES.VIDEO) return 'CameraVideo'
      if (this.asset.type === ASSET_TYPES.AUDIO) return 'IconparkAudioFile'
      if (this.asset.type === ASSET_TYPES.PODCAST) return 'IconparkMicrophone'
      return 'IconparkFileQuestion'
    },
  },
  watch: {
    'asset.uploadStatus': {
      handler(v) {
        if (v == 'success' && !this?.asset?.url) {
          this.id = this.asset?.id
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    formatDateTime(expiresAt) {
      return expiresAt != null ? this.$options.filters.formatDateTime(expiresAt) : this.$t('assets.not-set')
    },
    ...mapMutations('assets', [
      'SET_ASSET_ID',
      'SET_SELECTED_DATA',
      'UPDATE_SELECTED_ASSET',
    ]),
    handleMouseEnter() {
      this.mouseOver = true
    },
    handleMouseLeave() {
      this.mouseOver = false
    },
    async focalPoint(asset) {
      const { data } = await readAsset(asset?.id)
      const res = await this.showFocalPointPopup({ imageId: asset?.id, focals: data.focalPoint ? { default: data.focalPoint } : null, from: 'assets' })
      if (res) {
        asset.focalPoint = res.default
        data.focalPoint = res.default
        await updateAsset(data)
      }
    },
    handleClick(e) {
      if (this.isPopup && this.isAfter) return
      if (this.disable) return
      const exists = this.selectedAssets.some(img => img?.id === this.asset?.id)

      if ((e.ctrlKey || e.metaKey) || this.selectedData.show) {
        // if ctrl / command key is pressed
        // add/remove asset to/from selected assets

        if (exists) {
          this.selectedAssets = this.selectedAssets.filter(img => img?.id !== this.asset?.id)
        } else if (this.asset.uploadStatus !== 'uploading') {
          this.selectedAssets = [...this.selectedAssets, this.asset]
        }
      } else if (e.shiftKey && this.selectedData.lastSelection && (this.selectedData.lastSelection.folderId === this.folderId || !this.folderId)) {
        // if shift key is pressed and last selection exists
        // select all assets between first and last selection

        const firstIndex = this.assets.findIndex(img => img?.id === this.asset?.id)
        const lastIndex = this.assets.findIndex(img => img?.id === this.selectedData.lastSelection?.id)
        const start = Math.min(firstIndex, lastIndex)
        const end = Math.max(firstIndex, lastIndex)
        const array = this.assets.slice(start, end + 1)

        this.selectedAssets = [
          ...this.selectedAssets,
          ...array.filter(x => {
            const alreadySelected = this.selectedAssets.some(img => img?.id === x?.id)
            const isUploading = x.uploadStatus === 'uploading'

            if (this.isPopup) {
              const isAfter = moment().isAfter(x.expiresAt)
              return !alreadySelected && !isUploading && !isAfter
            }

            return !alreadySelected && !isUploading
          }),
        ]
      } else {
        // if no meta key is pressed
        // select only this asset

        const otherFolderAssets = this.folderId ? this.selectedData.items.filter(x => x.folderId !== this.folderId) : []

        this.selectedAssets = this.asset.uploadStatus !== 'uploading' ? [...otherFolderAssets, this.asset] : [...otherFolderAssets]
      }
    },
    openAsset() {
      this.SET_ASSET_ID(this.asset?.id)
    },
    maybeOpenAsset() {
      if (this.isPopup) {
        if (!this.isAfter) {
          this.selectedAssets = [this.asset]
          this.$root.$emit('closeAssetsPopup')
        }
      } else {
        this.openAsset()
      }
    },
    async openImageEditor() {
      if (this.asset?.id) {
        const image = await this.showImageEditorPopup({ imageId: this.asset?.id })
        if (image) {
          this.$root.$emit('editedImage', image)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/bootstrap-extended/_include.scss"; // Bootstrap includes
@import "@core/scss/base/components/_include.scss"; // Components includes

.asset-display {
  background: #F5F5F5;
  border: 2px solid transparent;
  border-radius: 4px;
  overflow: hidden;

  .deleted-media {
    background-color: #F7F7F8;
  }

  .deleted-asset{
    background: white !important;
    background-image: linear-gradient(to top, #EBDDFD, #F3F2F4  ) !important;
    padding:9px;
    border-radius:12px;
  }

  .b-aspect-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: 0 !important;
    border-radius: 4px;
    overflow: hidden;
    border: 4px solid transparent;
  }

  img {
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}

.asset-resolution {
  color: white;
  background-color: rgba(18, 37, 62, 0.5);
  backdrop-filter: blur(10px);
  font-size: 13px;
  border-radius: 4px;
  padding: 0 4px;
  z-index: 99;
}

.asset-display-root.selected .b-aspect-content {
  border-color: #1d79f2;
  border-radius: 2%;
}

.asset-display-root {
  position: relative;

  .dropdown-holder {
    opacity: 0;
    z-index: 99;

    .asset-context-menu {
      height: calc(100% - 50px);
      flex-wrap: wrap;
      display: flex;
      flex-direction: column;
    }

    > .dropdown {
      > .btn {
        height: 30px;
        width: 75px;
        padding: 0;
      }
    }

  }
}

.asset-display-root:hover .dropdown-holder {
  opacity: 1 !important;
}

.asset-selected {
  position: absolute !important;
  top: -3px;
  right: -2px;
  z-index: 99;
  height: 25px;
  border-radius: 8px;
}

.asset-display-root {
  .selected-icon {
    background-color: #1d79f2;
    color: white;
    border: 2px solid white;
    border-radius: 20%;
  }

  .dropdown-icon-wrapper {
    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > .asset-context-menu-element, > .perm-wrapper {
      display: block;
      color: white;
    }
  }

  .asset-context-menu-element a {
    color: white;
  }

  .asset-display__file {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .file__card {
    width: 100%;
    height: 100%;
    margin-bottom: 0 !important;
  }

  .file__card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: auto;
    height: 100%;
  }

  .assets__uploading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    gap: 15px;
  }

  .assets__uploading-container__spinner {
    color: #206ED5 !important;
  }

  .assets__uploading-container__progress {
    width: 100%;
    height: 20px;
    position: relative;
    background-color: #6A9EE3 !important;
  }

  .assets__uploading-container__progress-bar {
    height: 20px;
    background-color: #206ED5 !important;
  }

  .assets__uploading-container__progress_label {
    width: 100%;
    position: absolute;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
  }

  .file__card-footer {
    padding: 8px 8px 0 !important;
  }

  .file__card-footer-filename {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .asset-overlay__expire {
    .asset-overlay__expire-expired {
      border-radius: 10px;
      color: #E34850;
      background: white;
      padding: 2px;
    }

    .asset-overlay__expire-info {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #EF8C13;
      border-radius: 100px;

      svg {
        position: relative;
        z-index: 11;
        border-radius: 1000px;
        color: #EF8C13;
      }

      &:before {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        z-index: 10;
        background-color: white;
      }
    }
  }

  #select-asset {
    height: 16px;
    width: 16px;
    border-radius: 4px;
  }

  @media screen and (max-width: 1200px) {
    .dropdown-holder {
      height: 94px !important;
    }
    .dropdown-icon-wrapper {
      height: 80% !important;
    }
  }
}

.asset-details {
  margin: 7px;

  .asset-details__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    color: #1D417B;
    font-size: 14px;
  }

  .asset-details__date {
    color: #A3B0C2;
    font-size: 12px;
    margin: 0;
  }

  .asset-details__source-author {
    color: #A3B0C2;
    font-size: 12px;
    margin: 0;
    line-height: 18px;

    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    .asset-details__circle {
      height: 5px;
      width: 5px;
      background-color: #A3B0C2;
      border-radius: 50%;
      display: inline-block;
      margin-bottom: 2px;
    }
  }

  .asset-details__tags {
    color: #052D61;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: 0.5rem;

    .asset-details__tag {
      border: 1px solid #E0E5EB;
      border-radius: 15px;
      padding: 2px 6px;
      width: fit-content;
      background: #F4F6FA;

      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      > a {
        padding: 0;
        color: #052D61;
        font-size: 12px;
        width: auto;
      }

      > a:hover {
        background-color: transparent;
      }
    }

    > button {
      height: auto;
    }

    > ul {
      min-width: fit-content;
      padding: 8px 6px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      box-shadow: 0 4px 16px rgba(44, 44, 44, 0.16);
    }

  }
}

.video__play-icon {
  color: white;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  aspect-ratio: 1/1;

  height: 30%;
  min-height: 48px;
  max-height: 55px;
  border-radius: 40px;
  background: rgba(3, 28, 60, 0.30);
  backdrop-filter: blur(6px);
}

.video__failed__text, .video__processing__text {
  font-size: 10px;
}

.video__icons {
  color: white;
  position: absolute;
  left: 4px;
  bottom: 4px;
  z-index: 1;
  display: flex;
  gap: 4px;

  .video__icon {
    width: 22px;
    height: 22px;
    background: rgba(3, 28, 60, 0.30);
    backdrop-filter: blur(6px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }

  .video__vp-icon {
    width: 6px;
    height: 16px;
  }
}

.file__card-image-container {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 96px
  }
}

.asset-display-root {
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>
