<template>
  <div class="asset-display__list">
    <div
      v-if="props.column.field === 'file'"
      class="file-column"
    >
      <div
        v-if="props.row.type !== ASSET_TYPES.FILE && !isFailedLoading"
        style="width:72px; height:72px; position:relative; flex-shrink: 0;display: flex;justify-content: center;align-items: center;"
      >
        <template v-if="props.row.type === ASSET_TYPES.VIDEO || props.row.type === ASSET_TYPES.AUDIO || props.row.type === ASSET_TYPES.PODCAST">
          <div class="video__play-icon">
            <GjIcon
              :name="typeIcon"
              size="24"
            />
          </div>
          <div
            v-if="attrs.isLiveStream === 'true' || attrs.isLiveStream === true"
            class="video__live-stream-icon"
          >
            <div class="video__live-stream-icon__dot" />
            <span class="video__live-stream-icon__text">
              {{ $t('assets.videos.live') }}
            </span>
          </div>
          <div
            v-else-if="props.row.status === ASSET_STATUSES.PROCESSING"
            class="video__processing-icon"
          >
            <GjIcon
              name="IconparkLoadingOne"
              size="12"
              class="text-primary"
            />
          </div>
          <div
            v-else-if="props.row.status === ASSET_STATUSES.FAILED"
            class="video__failed-icon text-danger"
          >
            <GjIcon
              name="CloseCancelCircleFilled"
              size="12"
            />
          </div>
        </template>
        <div
          v-if="isDeleted"
          class="deleted-media"
        >
          <span class="deleted-asset">
            <GjIcon
              name="HideAlt"
              size="28"
              style="fill: #9595A0;"
            />
          </span>
        </div>
        <b-img
          id="at-media-list-img"
          rounded
          :src="displayURL"
          blank-color="#ccc"
          :fluid="true"
          :center="true"
          height="72px"
          width="72px"
          style="min-width:72px; min-height: 72px; max-width: 72px; max-height: 72px;"
          @error="() => isFailedLoading = true"
        />
      </div>
      <div
        v-else
        class="file__card-body media-list-view--color"
      >
        <div
          v-if="props.row.type === ASSET_TYPES.VIDEO || props.row.type === ASSET_TYPES.AUDIO || props.row.type === ASSET_TYPES.PODCAST"
          class="video__play-icon"
        >
          <GjIcon
            :name="typeIcon"
            size="24"
          />
        </div>
        <b-spinner
          v-if="props.row.uploadStatus === 'uploading'"
          class="assets__uploading-container__spinner"
        />
        <div
          v-else
          style="display: flex; justify-content: center; align-items: center;"
        >
          <img
            v-if="props.row.type === ASSET_TYPES.FILE || props.row.type === ASSET_TYPES.ASSET"
            :src="require('@/assets/images/icons/file.svg')"
            style="width: 100%;"
          >
          <img
            v-if="props.row.type === ASSET_TYPES.VIDEO_PLAYLIST || props.row.type === ASSET_TYPES.VIDEO"
            :src="require('@/assets/images/icons/video-playlist.svg')"
            style="width: 100%;"
          >
          <img
            v-if="props.row.type === ASSET_TYPES.AUDIO"
            :src="require('@/assets/images/icons/audio.svg')"
            style="width: 100%;"
          >
          <img
            v-if="props.row.type === ASSET_TYPES.PODCAST"
            :src="require('@/assets/images/icons/podcast.svg')"
            style="width: 100%;"
          >
        </div>
      </div>
      <div class="file-column-name-details">
        <h6 class="mb-0 img-title">
          {{ props.row.title || props.row.fileName }}
        </h6>
        <div class="asset-attrs">

          <template v-if="props.row.type === ASSET_TYPES.VIDEO || props.row.type === ASSET_TYPES.AUDIO || props.row.type === ASSET_TYPES.PODCAST">

            <div class="video__icon">
              <img
                src="@/assets/icons/vp-icon-gray.svg"
                alt="Vp Icon"
                height="16"
              >
            </div>
            <div
              v-if="attrs.isLive === 'true' || attrs.isLive === true"
              class="video__icon"
            >
              <GjIcon
                name="Livestream - main"
                size="16"
              />
            </div>

            <div
              v-if="props.row.expiresAt"
              class="video__icon"
            >
              <GjIcon
                v-if="isAfter(props.row)"
                v-b-tooltip
                :title="$t('assets.expired-date', { date: formatDateTime(props.row.expiresAt) })"
                class="asset-attrs__expire-expired"
                name="Denied"
                size="16"
              />
              <span
                v-else
                class="asset-attrs__expire-info"
              >
                <GjIcon
                  v-b-tooltip
                  name="Info"
                  size="16"
                  :title="$t('assets.expires-date', { date: formatDateTime(props.row.expiresAt) })"
                />
              </span>
            </div>
            <!-- </div> -->
          </template>
        </div>
      </div>
    </div>
    <div v-if="props.column.field === 'type'">
      {{ props.row.contentType }}
    </div>
    <div v-if="props.column.field === 'date'">
      {{ props.row.createdAt | formatDate }}
    </div>
    <div v-if="props.column.field === 'resolution'">
      <template v-if="props.row.type === ASSET_TYPES.IMAGE">
        {{ props.row.width }} x {{ props.row.height }}
      </template>
    </div>
    <div v-if="props.column.field === 'duration'">
      <template v-if="(props.row.type === ASSET_TYPES.VIDEO || props.row.type === ASSET_TYPES.AUDIO) && props.row.duration >= 0">
        {{ timeDuration(props.row.duration) }}
      </template>
      <template v-else>
        N/A
      </template>
    </div>
    <div v-if="props.column.field === 'file-size'">
      <template v-if="props.row.type === ASSET_TYPES.IMAGE || props.row.type === ASSET_TYPES.FILE">
        {{ props.row.size && bytesToSize(props.row.size, 2) }}
      </template>
    </div>
    <div v-if="props.column.field === 'more-options'">
      <b-dropdown
        v-if="!isDeleted"
        right
        variant="flat"
        class="dropdown-icon-wrapper"
      >
        <template #button-content>
          <b-button
            id="at-media-more-options-button"
            variant="btn-outline-white"
          >
            <GjIcon name="Moreoptions" />
          </b-button>
        </template>
        <b-dropdown-item @click.stop="openAsset">
          <span class="d-flex align-items-center">
            <GjIcon
              name="Edit"
              size="20"
            />
            <p class="p-0 m-0 ml-50">
              {{ $t('assets.assets-context-menu.details') }}
            </p>
          </span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="props.row.type === ASSET_TYPES.IMAGE"
          v-permission="['canUploadAsset', { folderId: props.row.folderId }]"
          @click.stop="openImageEditor"
        >
          <span class="d-flex align-items-center">
            <GjIcon
              name="Crop"
              size="20"
            />
            <p class="p-0 m-0 ml-50">
              {{ $t('assets.assets-context-menu.edit') }}
            </p>
          </span>
        </b-dropdown-item>
        <b-dropdown-item
          id="at-media-dropdown-delete-button"
          v-permission="['canDeleteAsset', { id: props.row.id }]"
          @click="$emit('deleteAsset', props.row)"
        >
          <span class="d-flex align-items-center">
            <GjIcon
              name="Delete"
              size="20"
            />
            <p class="p-0 m-0 ml-50">
              {{ $t('assets.assets-context-menu.delete') }}
            </p>
          </span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { ASSET_STATUSES, ASSET_TYPES } from '@/codex-sdk/assets'
import { bytesToSize, timeDuration } from '@/utils/helpers'
import { mapMutations } from 'vuex'
import moment from 'moment'
import gql from 'graphql-tag'

export default {
  name: 'AssetListDisplay',
  inject: ['showImageEditorPopup'],
  apollo: {
    currentAsset: {
      query: gql`
        query ($id: String!) {
         currentAsset: asset (id: $id){
            id
            folderId
            type
            title
            contentType
            createdAt
            expiresAt
            size
            url(transformation: {width: 200,height: 200,quality: 60, resize: FILL})
            fileName
            caption
            alt
            source
            author
            copyright
            tags
            attrs
            width
            height
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          id: this.id,
        }
      },
      result(result) {
        if (result?.data?.currentAsset) {
          this.UPDATE_SELECTED_ASSET(result.data.currentAsset)
          Object.assign(this.asset, result.data.currentAsset)
        }
        return result
      },
      skip() {
        return !this.id
      },
    },
  },
  props: {
    props: Object,
  },
  data() {
    return {
      ASSET_TYPES,
      ASSET_STATUSES,
      bytesToSize,
      id: null,
      timeDuration,
      isFailedLoading: false,
    }
  },
  computed: {
    isDeleted() {
      return this.props?.row?.status === ASSET_STATUSES.DELETED
    },
    displayURL() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (!(this.props?.row?.url ?? this.currentAsset?.url)) this.isFailedLoading = true
      return this.props?.row?.url ?? this.currentAsset?.url
    },
    attrs() {
      return this.props?.row?.attrs || {}
    },
    typeIcon() {
      if (this.props?.row?.type === ASSET_TYPES.VIDEO) return 'CameraVideo'
      if (this.props?.row?.type === ASSET_TYPES.AUDIO) return 'IconparkAudioFile'
      if (this.props?.row?.type === ASSET_TYPES.PODCAST) return 'IconparkMicrophone'
      return 'IconparkFileQuestion'
    },
  },
  watch: {
    'props.row.uploadStatus': {
      handler(v) {
        if (v == 'success' && !this.props?.row?.url) {
          this.id = this.props.row.id
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('assets', [
      'SET_ASSET_ID',
      'UPDATE_SELECTED_ASSET',
    ]),
    formatDateTime(expiresAt) {
      return expiresAt != null ? this.$options.filters.formatDateTime(expiresAt) : this.$t('assets.not-set')
    },
    isAfter(asset) {
      return moment().isAfter(asset.expiresAt)
    },
    openAsset() {
      this.SET_ASSET_ID(this.props.row.id)
    },
    async openImageEditor() {
      if (this.props.row.id) {
        const image = await this.showImageEditorPopup({ imageId: this.props.row.id })
        if (image) {
          this.$root.$emit('editedImage', image)
        }
      }
    },
  },
}
</script>

<style lang="scss">
.asset__check-expire_date {
  font-weight: 600;
  color: #667C99;
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-size: 12px;
  }
}

.video__play-icon-container {
  position: absolute;;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.asset-display__list {

  .deleted-media {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #F7F7F8;
  }

  .deleted-asset{
    background: white !important;
    background-image: linear-gradient(to top, #EBDDFD, #F3F2F4  ) !important;
    padding:9px;
    border-radius:12px;
  }

  .video__processing-icon {
    padding: 6px auto;
  }

  .video__play-icon {
    color: white;
    position: absolute;
    z-index: 1;

    width: 30%;
    min-width: 28px;
    height: 30%;
    min-height: 28px;
    border-radius: 28px;
    background: rgba(3, 28, 60, 0.30);
    backdrop-filter: blur(6px);
  }

  .file__card-body {
    width:72px;
    height:72px;
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .assets__uploading-container__spinner {
    color: #206ED5 !important;
  }

  .assets__uploading-container__progress {
    width: 100%;
    height: 25px;
    position:relative;
    background-color: #6A9EE3 !important;
  }

  .assets__uploading-container__progress-bar {
    height: 25px;
    background-color: #206ED5 !important;
  }

  .assets__uploading-container__progress_label {
    width: 100%;
    position: absolute;
    color: #fff;
    font-size: 14px;
    font-weight:500;
  }
  .asset-attrs {
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;

    .asset-attrs__expire {
      .asset-attrs__expire-expired {
        border-radius: 10px;
        color: #E34850;
        background: white;
        padding: 2px;
      }
      .asset-attrs__expire-info {
        position: relative;
        display:flex;
        align-items: center;
        justify-content: center;
        background-color: #EF8C13;
        border-radius: 100px;
        width: fit-content;
        svg {
          position: relative;
          z-index: 11;
          border-radius: 1000px;
          color: #EF8C13;
        }
        &:before {
          content: '';
          width: 8px;
          height: 8px;
          position: absolute;
          z-index: 10;
          background-color: white;
        }
      }
    }

    .video__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 22px;
      background: #EDEFF3;
      border-radius: 4px;
      color: #667C99;
    }
  }
}
</style>
